import { capitalize } from "lodash";

export function isValidUUID(uuid: string): boolean {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(uuid);
}

export function isEmpty(data: any): boolean {
    if( !data ) return true;
    if( Array.isArray(data) ) return isEmptyArray(data);
    if( typeof(data) === 'undefined' ) return true;
    if( typeof(data) === 'number' ) return isEmptyNumber(data as number);
    if( typeof(data) === 'string' ) return isEmptyString(data as string);

    return false;
}
export function isNotEmpty(data: any): boolean {
    return !isEmpty(data);
}

export function isEmptyString(s: string): boolean {
    if( !s ) return true;
    return s.trim().length === 0;
}
export function isEmptyNumber(n: number): boolean {
    return isZero(n);
}

export function isEmptyArray(a: Array<any>): boolean {
    if( !a ) return true;
    return a.length === 0
}
export function isNotEmptyArray(a: Array<any>): boolean {
    return !isEmptyArray(a);
}

export function isZero(n: number): boolean {
    if( !n ) return true;
    return n === 0;
}
export function isNotZero(n: number): boolean {
    return !isZero(n);
}

export function fullName(given_name: string, family_name: string): string {
    if( isNotEmpty(family_name) ) {
        if( isNotEmpty(given_name) ) return `${capitalize(given_name)} ${capitalize(family_name)}`
        return capitalize(family_name);
    } else if( isNotEmpty(given_name) ) return capitalize(given_name)
    return '';
}